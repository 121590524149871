<template>
    <div class="area_body">
        <div v-if="broadcastLoader">
            <quote-loader />
        </div>
        <div class="dashboard" v-else>
            <div class="msg_preview">
                <div class="email-logo" v-if="broadcast.show_email_logo">
                    <img :src="broadcast.email_logo ? broadcast.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                </div>
                <h2 class="subject-line">{{ broadcast.subject }}</h2>
                <div class="redactor-styles redactor-in redactor-in-0 p-0 content-prefix" v-html="broadcast.content"></div>
                <!-- <div v-html="generateSignature(broadcast.is_signature, broadcast.signature_id)"></div> -->
            </div>
            <div class="msgFooter text-center mt-2 mb-2">
                <p>
                    <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                        <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                        <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    </template>
                    <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                </p>
                <!-- <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p> -->
                <!-- <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'

    export default {
        name: 'Broadcast Public View',

        data () {
            return {
                whiteLabel: {},
                user: {},
                membership: {},
            }
        },

        watch: {
            broadcast (broadcast) {
                const vm = this;

                vm.user         = broadcast.user;
                vm.membership   = broadcast.user ? broadcast.user.user_membership : {};
            },
        },

        computed: {
            ...mapState({
                broadcast: state => state.broadcastModule.broadcastDetail,
                broadcastLoader: state => state.broadcastModule.broadcastDetailLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.getBroadcastDetails(vm.$route.params.id);
        },

        methods: {
            ...mapActions({
                getBroadcastDetails: 'broadcastModule/getBroadcastDetails'
            }),
        }
    };
</script>

<style scoped>
    .dashboard{
        background: transparent;
        padding: 30px 10px;
        align-items: center;
    }
    .tab .dashboard{
        padding: 30px;
    }
    .cell .dashboard{
        padding: 15px;
    }
    .msg_preview{
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }
    /* .msg_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    } */
    .cell .msg_preview{
        padding: 20px;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        text-align: left;
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .sender_info .info p {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #999;
    }
    :deep(.desk .redactor-styles.redactor-in.email-preview p) {
        font-size: 15px !important;
        line-height: 22px !important;
    }
    :deep(.tab .redactor-styles.redactor-in.email-preview p){
        font-size: 14px !important;
        line-height: 19px !important;
    }
    :deep(.cell .redactor-styles.redactor-in.email-preview p){
        font-size: 11px !important;
        line-height: 15px !important;
    }
    .desk .msg_preview h2{
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
        text-align: left;
    }
    .tab .msg_preview h2{
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
        text-align: left;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
        text-align: left;
    }
    .msgFooter p{
        font-size: 13px;
        line-height: 21px;
    }
    .tab .msgFooter p{
        font-size: 12px;
        line-height: 19px;
    }
    .cell .msgFooter p{
        font-size: 11px;
        line-height: 17px;
    }
    .msgFooter p a{
        text-decoration: none;
    }
    .area_body {
        position: relative;
        /* height: calc(100vh - 110px); */
        height: auto;
    }
    .quote_wpr {
        height: 70vh;
        justify-content: center;
    }
    .subject-line {
        font-size: 16px;
        text-align: left;
        /* padding-left: 70px; */
        margin-bottom: 25px;
    }

    .subject-line::before {
        /* content: 'Subject: ';
        margin-left: -70px; */
    }
    .redactor-styles.content-prefix :deep(h2){
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 28px;
    }
    .redactor-styles.content-prefix :deep(p){
        margin-bottom: 15px;
        font-size: 14px !important;
        line-height: 22px;
    }
    .redactor-styles.content-prefix :deep(ul){
        margin-left: 25px;
    }
    .redactor-styles.content-prefix :deep(ul li){
        padding: 8px 0;
        font-size: 14px;
        line-height: 22px;
    }
    @media(max-width: 599px){
        .redactor-styles.content-prefix :deep(h2){
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 25px
        }
        .redactor-styles.content-prefix :deep(p){
            margin-bottom: 12px;
            font-size: 13px !important;
            line-height: 18px;
        }
        .redactor-styles.content-prefix :deep(ul){
            margin-left: 20px;
        }
        .redactor-styles.content-prefix :deep(ul li){
            padding: 6px 0;
            font-size: 13px;
            line-height: 18px;
        }
    }
    @media(max-width: 420px){
        .msg_preview{
            padding: 30px 20px;
        }
        .redactor-styles.content-prefix :deep(h2){
            margin-bottom: 12px;
            font-size: 15px;
            line-height: 22px
        }
        .redactor-styles.content-prefix :deep(p){
            margin-bottom: 10px;
            font-size: 11px !important;
            line-height: 16px;
        }
        .redactor-styles.content-prefix :deep(ul){
            margin-left: 20px;
        }
        .redactor-styles.content-prefix :deep(ul li){
            padding: 4px 0;
            font-size: 11px;
            line-height: 16px;
        }
    }
</style>

